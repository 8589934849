import React from 'react';
import { useLocation } from 'react-router-dom'

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';


import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import RedirectAnonymous from './Authentication/RedirectAnonimus';
import useRemoteServer from '../hooks/useRemoteServer';
// import useExportToXLS from '../hooks/useExportToXLS';
import XLSX from "xlsx";


function Cpanel(){
    const location = useLocation();
    
    //define filename
    const dateNow = new Date();
    const day = dateNow.getDate();
    const month = dateNow.getMonth() + 1;
    const year = dateNow.getFullYear();
    const fileName = 'TAI12_exports_'+ day + '_' + month + '_' + year;

    // Function for file export
    const exportJS = (data, format) => {
        // const fields = Object.keys(data[0]); //generate system headers (from CMS collection)
        const customFields = [
            'Лекар', 
            'Дата',
            'УИН', 
            'Пол', 
            'Възраст', 
            'Образование', 
            'Тютюнопушене', 
            'Диагноза', 
            'Давност на заб.',
            'Вид инхалатор', 
            'Въпрос 1','Въпрос 2','Въпрос 3','Въпрос 4','Въпрос 5','Въпрос 6','Въпрос 7','Въпрос 8','Въпрос 9','Въпрос 10','Въпрос 11','Въпрос 12',
            'Сбор 1-5', 'Сбор 6-10', 'Сбор 11-12',
            'Резултат TAI 10', 'Резултат TAI 12'
        ];
    
        const wb = XLSX.utils.book_new(); // book
        // const ws = XLSX.utils.json_to_sheet(data, {header: fields}); // generate with system headers
        const ws = XLSX.utils.json_to_sheet(data, {skipHeader: true, origin: 'A2' }); // remove system headers
        XLSX.utils.sheet_add_aoa(ws, [customFields]); //add custom headers
    
        XLSX.utils.book_append_sheet(wb, ws, "medic data"); //  sheet name
        XLSX.writeFile(wb, fileName+'.'+format, {bookType: format === 'xls'? format='biff8' : format});
    };

    const remoteServer = useRemoteServer();
    const [filterData, setFilterData] = React.useState();
    const [tableFormat, setTableFormat] = React.useState();


    const handleMedicID = (ev) => {
        setFilterData(ev.target.value)
    }
    const handlFormat = (ev) => {
        setTableFormat(ev.target.value)
    }
    const handleSubmit = (ev) => {
        ev.preventDefault();

        let objectData = [{}];
        if (filterData === "" || filterData === undefined || filterData.match(/^ *$/) !== null) {
            console.log('empty table');
        } else {
            // Process the 
            objectData = filterData.split(",").map(elem => {
                return { 'Medic_id': elem };
            })
        }   

        // Fetch filtered (from input field) data 
        remoteServer.getFilteredTestData(objectData).then(
                (result)=>{
                    if(result.error){
                        console.log(result.error);
                    } else {

                        const exportEntries = result.entries.map(elem => {
                            return {
                                "Medic_name": elem.Medic_name,
                                "Date": elem.Date,
                                "Medic_id": elem.Medic_id,
                                "Sex": elem.Sex,
                                "Age": elem.Age,
                                "Education": elem.Education,
                                "Smoker": elem.Smoker,
                                "Diagnosis": elem.Diagnosis,
                                "Disease_period": elem.Disease_period,
                                "Inhaler_type": elem.Inhaler,
                                "Q1": elem.Q1,
                                "Q2": elem.Q2,
                                "Q3": elem.Q3,
                                "Q4": elem.Q4,
                                "Q5": elem.Q5,
                                "Q6": elem.Q6,
                                "Q7": elem.Q7,
                                "Q8": elem.Q8,
                                "Q9": elem.Q9,
                                "Q10": elem.Q10,
                                "Q11": elem.Q11,
                                "Q12": elem.Q12,
                                "Score_1_5": elem.Score_1_5,
                                "Score_6_10": elem.Score_6_10,
                                "Score_11_12": elem.Score_11_12,
                                "TAI10_Score": Number(elem.Score_1_5) + Number(elem.Score_6_10),
                                "TAI12_Score": elem.Score_11_12,
                            }
                        })
                        
                        // Delete all data before fetch new one
                        remoteServer.deletePrevExportData().then(
                            (result)=>{
                                if(result.error){
                                    console.log(result.error);
                                } else {
                                    // Save filtered data
                                    remoteServer.saveExportData(exportEntries).then(
                                        (result)=>{
                                            if(result.error){
                                                console.log(result.error);
                                            } else {
                                                console.log(result);
                                                const filteredResult = result.map(item => { 
                                                    const { _by, _created, _id, _modified, ...other } = item; 
                                                    return other; 
                                                })
                                                // Open new window to download file with filtered data 
                                                if (tableFormat){
                                                    exportJS(filteredResult, tableFormat)
                                                    // window.open(`https://smallairways.clientric.website/collections/export/exported_test_results?type=`+tableFormat+`&options[pretty]=1&options[fields][_id]=0`)
                                                } else {
                                                    exportJS(filteredResult, 'xls')
                                                    // window.open('https://smallairways.clientric.website/collections/export/exported_test_results?type=xlsx&options[pretty]=1&options[fields][_id]=0');
                                                }
                                            }
                                        }
                                    )
                                }
                            }
                        )
                    }
                }
            )
    }

    return(
        <React.Fragment>
            <RedirectAnonymous url={location}/>
            <div className="p-5 mt-5 mx-4 container-fluide bg-light border border-2 border-secondary">
                <h2 className="text-secondary text-center pb-5">Експортиране на данни</h2>
                <form onSubmit={handleSubmit} className="tai-test">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8}>
                            <TextField 
                                fullWidth
                                id="standard-basic" 
                                className="" 
                                label="УИН" 
                                type="text"
                                variant="filled"
                                onChange={handleMedicID}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl variant="filled" fullWidth>
                                <InputLabel htmlFor="filled-format-native-simple">Формат</InputLabel>
                                    <Select
                                    native
                                    value={tableFormat}
                                    onChange={handlFormat}
                                    inputProps={{
                                        name: 'format',
                                        id: 'filled-format-native-simple',
                                    }}
                                    >
                                    <option value="" />
                                    {/*<option value='xlsx'>XLSX</option>
                                    <option value='xlsm'>XLSM</option>
                                    <option value='xlsb'>XLSB</option> */}
                                    <option value='xls'>XLS</option>
                                    <option value='csv'>CSV</option>
                                    <option value='ods'>ODS</option>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    
                    <div className="pt-3 text-secondary">* Може да въведете повече от един УИН, като използвате запетая за разделител.</div>
                    <div className="pt-2 text-secondary">* Оставете празно полето УИН за да свалите цялата информация.</div>
                    <div className="text-center">
                        <button type="submit" className="mt-5 btn btn-blue">Експортирай</button>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}

export default Cpanel;
