import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";  
import {
    ListItem,
    ListItemText,
    Collapse,
    List
} from "@material-ui/core";
import {ExpandLess, ExpandMore } from "@material-ui/icons";



function MainNavMenuMobile({ item }) {

    const loggedIn = localStorage.getItem('taitest.loggedin');
   
    const [openEl, setOpenEl] = React.useState(false);
   
    const handleClick = () => {
        setOpenEl(!openEl);
    };

    const link = item.Alias_slug === 'home' ? '' : item.Alias_slug;
       
    if(item.children.length > 0){
        return (
            <div key={item.Title} className="align-items-center">
                <ListItem  
                    button
                    onClick={handleClick} 
                    className="text-secondary text-uppercase w-100"
                >
                    <ListItemText>
                        {item.Title}
                        {openEl ? <ExpandLess fontSize="small"/> : <ExpandMore fontSize="small"/>}
                    </ListItemText>
                </ListItem>
                
                <Collapse
                    in={openEl} 
                    timeout="auto"
                    unmountOnExit
                >
                    <List className="pt-0">
                        {item.children.map((child) => 
                            {
                                if(loggedIn) {
                                    return <ListItem key={child.Title} className="pt-1">
                                                <Link to={`/${link}/${child.Alias_slug}`} className="ps-3 w-100 align-items-center text-decoration-none">
                                                    {child.Title}
                                                </Link> 
                                            </ListItem>
                                } else if (!loggedIn&&!child.Private_page) {
                                    return <ListItem key={child.Title} className="pt-1">
                                                <Link to={`/${link}/${child.Alias_slug}`} className="ps-3 w-100 align-items-center text-decoration-none">
                                                    {child.Title}
                                                </Link> 
                                            </ListItem>
                                }
                            }
                        )}
                    </List>
                </Collapse>
            </div>
        )
    } else {
        return (
            <ListItem button>
                <Link to={`/${link}`} key={item.Title} className="d-flex align-items-center text-uppercase text-secondary text-decoration-none">
                    <ListItemText className="text-left" primary={item.Title} />
                </Link>
            </ListItem>
        )
    }
}

export default MainNavMenuMobile;
