import React from 'react';
import { COCKPIT_HOST, COCKPIT_UPLOADS_DIR } from "../config";

import PageContainer from './PageContainer';
import useRemoteServer from '../hooks/useRemoteServer';

function UsefulNfo() {

    const module = "usefulInfo";

    const remoteServerInterface = useRemoteServer();
    const [usefulNfo, setUsefulNfo] = React.useState({Documents:[], FAQ:[]});
    const loggedIn = localStorage.getItem('taitest.loggedin');

React.useEffect(async ()=>{
    const list = await remoteServerInterface.getSingleton(module);
    setUsefulNfo(list);
},[])    

//Adding download PDF buttons
const listDocks = usefulNfo.Documents.map((val, ind)=>{
    if((ind<2)||(loggedIn)){
        return  <a key={ind} target="_blank" href={val.value ? COCKPIT_HOST+val.value.link : null} className="col-lg-3 col-md-3 col-sm-12 pb-md-0 pb-3 d-flex align-items-center text-decoration-none">
                    <div className="box-link d-flex flex-column align-items-center justify-content-center w-100">
                        <div className="box-link-title pb-3">
                            <h1 className="fw-normal">{val.value ? val.value.btn_label : null}</h1>
                        </div>
                        <p className="box-link-descr small fst-italic text-center text-uppercase px-3 text-white">{val.value ? val.value.btn_subtitle : null}</p>
                    </div>
                </a>
    }

});

//Adding question-answers pair
const listFAQ = usefulNfo.FAQ.map((val, ind)=>{
    return  <div key={ind} className="pb-3">
                <h5 className="pb-2 text-blue fst-italic border-bottom border-1 border-light">{val.value.Question}</h5>
                <p className="">{val.value.Answer}</p>
            </div>
});

    return (
        <PageContainer>
        {/*----------------------------  FOR DELETE  ------------------------*/}
            <div className="text-center pb-4">
                <img height="79" src={COCKPIT_HOST + COCKPIT_UPLOADS_DIR + "/2021/05/10/609977c741526tai_home.png"}/>
            </div>
        {/*----------------------------  FOR DELETE  ------------------------*/}
            <h3 className="ps-2 pb-2 fst-italic text-secondary border-bottom border-2 border-secondary">{usefulNfo.Info_text_heading ? usefulNfo.Info_text_heading : null}</h3>
            <article className="pb-4 pt-1 pb-md-5" dangerouslySetInnerHTML={{__html: usefulNfo.Info_text}}></article>
            <article className="pb-4 pt-1 pb-md-5">
                <div className="row pt-3 pb-5 mb-0 mb-sm-4 justify-content-center">
                    {listDocks}
                    {/* {loggedIn && (<div className="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center">
                        <Link to="/tai12" className="text-decoration-none">
                            <div className="box-link d-flex flex-column align-items-center justify-content-center">
                                <div className="box-link-title pb-3">
                                    <h1 className="fw-normal">{homeData.Link_page ? homeData.Link_page[1].value.Link_Title : null}</h1>
                                </div>
                                <p className="box-link-descr small fst-italic text-center text-uppercase px-3 text-white">{homeData.Link_page ? homeData.Link_page[1].value.Link_Descr : null}</p>
                            </div>
                        </Link>
                    </div>)} */}
                </div>
            </article>
            <h3 className="ps-2 pb-2 fst-italic text-secondary border-bottom border-2 border-secondary">{usefulNfo.FAQ_heading ? usefulNfo.FAQ_heading : null}</h3>
            <article className="pt-4">
                {listFAQ}
            </article>
        </PageContainer>
    )
}

export default UsefulNfo;
