
import React from "react";
import { useLocation } from 'react-router-dom';

import RedirectAnonymous from '../Authentication/RedirectAnonimus';
import ModalVideo from "./ModalVideo"
import Metatags from "../MetaData";

function BlogDetailed({blogItemData, alaisArticle}) {

    const location = useLocation();
    const articleData = Object.assign({Audience: ''}, blogItemData.entries.find(item => item.Alias_slug === alaisArticle ));

    return (
        <React.Fragment>
            {articleData.Audience === "MEDIC" && (<RedirectAnonymous url={location}/>)}
            <Metatags title={articleData.Meta_title} description={articleData.Meta_description}></Metatags>
            <div className="row pt-4">
                <div>
                    <h2 className="text-gray fw-bold">{articleData.Title}</h2>
                    <h4 className="pt-1 pb-2 text-secondary">Date: {articleData.Date}</h4>
                    {articleData.Video && <ModalVideo videoData={articleData}/>}
                    {articleData.Description_preview && <p className="pt-2 pt-sm-4 fw-bold">{articleData.Description_preview}</p>}
                    {articleData.Subtitle && <h4 className="py-2 text-secondary">{articleData.Subtitle}</h4>}
                    {articleData.Desctiption && <div><p className="fw-bold" dangerouslySetInnerHTML={{__html: articleData.Desctiption}}></p></div>}
                </div>
            </div>
        </React.Fragment>
    );
}

export default BlogDetailed;
