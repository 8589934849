// import { LocalHospital } from '@material-ui/icons';
import React from 'react';
import {COCKPIT_API} from "../config"


const getToken = '?token=460ed58b5c2940fea45d4b951379fd';
const saveToken = '?token=de6aab0768495d7ae8be4b4c767de8';
const userToken = '?token=9a074a6201f321175f09024e8a42cc';
const apiEntry = COCKPIT_API;

const useRemoteServer = () => {

    const serverInterface = React.useMemo(()=>{
       
        return { 
            getNavigation: async function() {
                const url = apiEntry + '/collections/get/navigation' + getToken;
                let response = await fetch(
                    url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            filter: { Publish: true },
                            sort: {_o: 1},
                        }),
                    }
                );
                let data = await response.json();
   
                return data;
            },
            getNavigationBlog: async function() {
                const url = apiEntry + '/collections/get/navigation' + getToken;
                let response = await fetch(
                    url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            filter: { Publish: true,  $and: [ {$or: [{"_pid": "612d1f30376465b06a0000a5"}]}]  },
                            fields: {Title: 1, Alias_slug: 1, Private_page: 1, _pid: 1, _id:1},
                            sort: {_o: 1},
                        }),
                    }
                );
                let data = await response.json();
   
                return data;
            },
            getBlogDataHome: async function(filteredData) {
                const url = apiEntry + '/collections/get/blog' + getToken;
                let response = await fetch(
                    url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            filter: filteredData,
                        }),
                    }
                );
                let data = await response.json();
                return data;
            },
            getBlogData: async function(type, startOrder, filterPatientOnly) {
                const filteredData = {"Theme.display" : type, "_o":{$gte: startOrder}};
                if (filterPatientOnly) {
                    Object.assign(filteredData, {"Audience": filterPatientOnly})
                }
                const url = apiEntry + '/collections/get/blog' + getToken;
                let response = await fetch(
                    url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            filter: filteredData,
                            sort: {_o: 1},
                            limit: 4,
                        }),
                    }
                );
                let data = await response.json();
                return data;
            },
            getBlogDataAboutUser: async function(startOrder, filterPatientOnly) {
                const filteredData = {"_o":{$gte: startOrder}};
                if (filterPatientOnly) {
                    Object.assign(filteredData, {"Audience": filterPatientOnly})
                }
                const url = apiEntry + '/collections/get/blog' + getToken;
                let response = await fetch(
                    url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            filter: filteredData,
                            sort: {_o: 1},
                            limit: 6,
                        }),
                    }
                );
                let data = await response.json();
                return data;
            },
            getTai10Questinos: async function() {
                const url = apiEntry + '/collections/get/tai10' + getToken;
                let response = await fetch(
                    url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({

                        }),
                    }
                );
                let data = await response.json();
                return data;
            },
            getTai12Questinos: async function() {
                const url = apiEntry + '/collections/get/tai12' + getToken;
                let response = await fetch(
                    url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({

                        }),
                    }
                );
                let data = await response.json();
                return data;
            },
            getMedicData: async function(medicID) {
                const url = apiEntry + '/collections/get/medics' + getToken;
                let response = await fetch(
                    url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            filter: { Medic_id: medicID}
                        }),
                    }
                );
                let data = await response.json();
                return data;
            },    
            saveMedicData: async function(medicName, medicId, numberTests, totalResult, entrieId) {
                const url = apiEntry + '/collections/save/medics' + saveToken;
                let response = await fetch(
                url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            data: {
                                Medic_name: medicName,
                                Medic_id: medicId,
                                Number_of_tests: numberTests,
                                Total_score: totalResult,
                                _id : entrieId
                            }
                        }),
                    }
                );
                let data = await response.json();
                return data;
            },   

            savePatientData: async function(patientData){
                const url = apiEntry + '/collections/save/promoMedics' + saveToken;
                let response = await fetch(
                    url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            data: patientData
                        }),
                    }
                );
                let data = await response.json();
   
                return data;
            },


            /* ==========    SINGLETONES REQUESTS    =========== */ 
            getSingleton: async function(singletonName) {
                const url = apiEntry + '/singletons/get/'+ singletonName + getToken;
                let response = await fetch(
                    url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                        
                        }),
                    }
                );
                let data = await response.json();
                return data;
            },


            /* ==========    USERS REQUESTS    =========== */ 
            postRegister: async function(name, pass, email, medicalId) {
                const url = apiEntry + '/cockpit/saveUser' + userToken;
                let response = await fetch(
                    url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            "user": {
                                "name": name,
                                "user": email,
                                "password": pass,
                                "email": email,
                                // "active": false,
                                "group": "medic",
                                "medic_id": medicalId
                            }
                        }),
                    }
                );
                let data = await response.json();
                console.log(data);
                return data;
            },

            postRegisterCollection: async function(name, email, medicalId) {
                const url = apiEntry + '/collections/save/registerMedics' + saveToken;
                let response = await fetch(
                    url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            "data": {
                                "Medic_name": name,
                                "Medic_id": medicalId,
                                "Medic_email": email,
                                "Active": false,
                                "Promo": false,
                            }
                        }),
                    }
                );
                let data = await response.json();
                console.log(data);
                return data;
            },

            postLogin: async function(user, pass) {
                const url = apiEntry + '/cockpit/authUser' + userToken;
                let response = await fetch(
                    url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            user: user,
                            password: pass
                        }),
                    }
                );
                let data = await response.json();
                return data;
            },

            getIsActivPromo: async function(filteredData) {
                const url = apiEntry + '/collections/get/registerMedics' + getToken;
                let response = await fetch(
                    url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            filter: filteredData,
                        }),
                    }
                );
                let data = await response.json();
   
                return data;
            },

            /* ==========    FORMS REQUESTS    =========== */ 

            postSaveForm: async function(name, email, medicalId) {
                const url = apiEntry + '/forms/submit/Register' + saveToken;
                let response = await fetch(
                    url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            "form": {
                                "Медицинско лице": name,
                                "УИН": medicalId,
                                "Е-mail": email,
                            }
                        }),
                    }
                );
                let data = await response.json();
                return data;
            },

            postSaveHow2UseForm: async function(name, email, hospital, question) {
                const url = apiEntry + '/forms/submit/How2Use' + saveToken;
                let response = await fetch(
                    url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            "form": {
                                "Име": name,
                                "Е-mail": email,
                                "Здравно заведение": hospital,
                                "Запитване": question,
                            }
                        }),
                    }
                );
                let data = await response.json();
                return data;
            },

            postContactsForm: async function(name, email, question) {
                const url = apiEntry + '/forms/submit/Contacts' + saveToken;
                let response = await fetch(
                    url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            "form": {
                                "Име": name,
                                "Е-mail": email,
                                "Съобщение": question,
                            }
                        }),
                    }
                );
                let data = await response.json();
                return data;
            },


            /* ==========   EXPORT TABLE   =========== */ 

            getFilteredTestData: async function(filteredData) {
                const url = apiEntry + '/collections/get/promoMedics' + getToken;
                let response = await fetch(
                url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            filter: {
                                $or: filteredData
                            },
                        }),
                    }
                );
                let data = await response.json();
                return data;
            }, 

            deletePrevExportData: async function() {
                const url = apiEntry + '/collections/remove/exported_test_results' + saveToken;
                let response = await fetch(
                url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            filter: {"_by": null}
                        }),
                    }
                );
                let data = await response.json();
                return data;
            },  

            saveExportData: async function(entries) {
                const url = apiEntry + '/collections/save/exported_test_results' + saveToken;
                let response = await fetch(
                url,
                    {
                        method: 'post',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            data: entries
                        }),
                    }
                );
                let data = await response.json();
                return Array.isArray(data) ? data : [data];
            },  

            // postExportTable: async function(collection) {
            //     const url = apiEntry + '/collections/export/' + {collection} + '?type=xlsx';
            //     let response = await fetch(
            //         url,
            //         {
            //             method: 'post',
            //             headers: { 'Content-Type': 'application/json' },
            //             body: JSON.stringify({
                           
            //             }),
            //         }
            //     );
            //     let data = await response.json();
            //     console.log(data);
            //     return data;
            // },
        }

        // https://smallairways.clientric.website/collections/export/medics?type=xlsx

    }, []);


    return serverInterface;
} 

export default useRemoteServer;