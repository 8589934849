import React from 'react';
import {PUBLIC_URL} from "./../config";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import useRemoteServer from './../hooks/useRemoteServer';
import InputField from './../input-field'


const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        background: '#AF0061',
        borderRadius: '0',
        marginTop: '24px',
        '&:hover' : {
            background: '#AF0061',
            opacity: '0.8'
        }
    },
}));


function isRequired(val) {
    return val.length > 0 ? '' : 'полето е задължително'
}
function isMoreThan3(val) {
    return (val.length === 0) || (val.length > 2) ? '' : 'не по-малко от 3 букви'
}
function isEmail(val) {
    const at = val.indexOf("@");
    const grDotIdx = val
        .split("")
        .reduce((acc, char, i) => char === '.' ? i : acc, 0);
    return ((val.length === 0) || (at > -1) && (grDotIdx > at)) ? '' : 'въведете валиден email'
}

function Contacts() {
    const classes = useStyles();

    const module = "contacts";

    const remoteServerInterface = useRemoteServer();
    const [contacts, setContacts] = React.useState({});

    React.useEffect(async () =>{
        const list = await remoteServerInterface.getSingleton(module);
        setContacts(list)
    },[])

    const defaultValues = {
        name: '',
        email: '',
        question: ''
    }
    const defaultErrors = {
        name: [],
        email: [],
        question: []
    }

    const [values, setValues] = React.useState(defaultValues);
    const [errors, setErrors] = React.useState(defaultErrors);

    const [openModal, setOpenModal] = React.useState(false);

    const handleSubmit = (ev)=>{
        ev.preventDefault();

        // initial check if the field is empty
        if (values.name.length === 0) {
            setErrors({"name": ["полето е задължително"], "email": [], "question": []})
        // initial check if the field is empty
        } else if (values.email.length === 0){
            setErrors({"name": [], "email": ["полето е задължително"], "question": []})
        // initial check if the field is empty
        } else if (values.question.length === 0){
            setErrors({"name": [], "email": [], "question": ["полето е задължително"]})
        //check for at least one error    
        } else if (errors.name.length === 0 && errors.email.length === 0 && errors.question.length === 0){
        
            remoteServerInterface.postContactsForm(values.name, values.email, values.question);
            //set empty form after submit
            setValues(defaultValues);
            setErrors(defaultErrors);
            setOpenModal(true);
        }
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    };
    
    return (
        <React.Fragment>
            <CssBaseline />
            <article className="container pt-5 mb-0 contacts" dangerouslySetInnerHTML={{__html: contacts.text_info}}></article>
            <Container component="main" maxWidth="xs">
                <div className="d-flex align-items-center flex-column mt-5 pb-5 pt-0 pt-sm-5">
                    <AlternateEmailIcon  className="font-size-80 text-secondary" fontSize="large"/>
                    <h3  className="pt-3">КОНТАКТИ</h3>
                    <form onSubmit={handleSubmit} className={classes.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <InputField
                                    label = "Име *"
                                    name = 'name'
                                    value={values.name}
                                    onChange={val => {
                                        const name = val;
                                        setValues(prev => ({
                                            ...prev,
                                            name
                                        }));
                                    }} 
                                    validations={[isRequired, isMoreThan3]}
                                    errors={errors.name}
                                    setErrors = {setErrors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputField
                                    label = "Електронен адрес *"
                                    name = 'email'
                                    value={values.email}
                                    onChange={val => {
                                        const email = val;
                                        setValues(prev => ({
                                            ...prev,
                                            email
                                        }));
                                    }} 
                                    validations={[isRequired, isEmail]}
                                    errors={errors.email}
                                    setErrors = {setErrors}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <InputField
                                    type="textarea"
                        
                                    numRows='5'
                                    label = "Съобщение *"
                                    name = 'question'
                                    value={values.question}
                                    onChange={val => {
                                        const question = val;
                                        setValues(prev => ({
                                            ...prev,
                                            question
                                        }));
                                    }} 
                                    validations={[isRequired]}
                                    errors={errors.question}
                                    setErrors = {setErrors}
                                />
                            </Grid>
                        </Grid>
                        <p className="text-gray pt-3">* задължителни полета</p>   
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Изпрати
                        </Button>
                        { /*<Grid container justify="flex-start">
                            <Grid item>
                            <Link href="preview/login" variant="body2" className="text-secondary">
                                Вече имате регистрация? Вход
                            </Link>
                            </Grid>
                        </Grid> */}
                    </form>
                </div>
                <section className="contacts pb-5">
                    <div className="">
                        <p  dangerouslySetInnerHTML={{__html: contacts.text_contacts}}></p>
                    </div>
                </section>  
            </Container>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="d-flex justify-content-center align-items-center"
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
                >
                <Fade in={openModal}>
                    <div className="bg-light p-5 text-center">
                        <h2 id="transition-modal-title" className="text-blue pb-4">Благодаря Ви, че се свързахте с нас</h2>
                        <p id="transition-modal-description" className="text-secondary">Ще отговорим посочения имейл възможно най-скоро.</p>
                    </div>
                </Fade>
            </Modal>
        </React.Fragment>
    );
}

export default Contacts;
