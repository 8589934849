import React from "react";
import { COCKPIT_HOST, COCKPIT_UPLOADS_DIR } from "../config";

import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import PageContainer from './PageContainer';
import useRemoteServer from '../hooks/useRemoteServer';
import InputField from '../input-field'

const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      background: '#AF0061',
      borderRadius: '0',
      marginTop: '24px',
      '&:hover' : {
          background: '#AF0061',
          opacity: '0.8'
      }
    },
  }));

function isRequired(val) {
    return val.length > 0 ? '' : 'полето е задължително'
}
function isMoreThan3(val) {
    return (val.length === 0) || (val.length > 2) ? '' : 'не по-малко от 3 букви'
}
function isEmail(val) {
    const at = val.indexOf("@");
    const grDotIdx = val
        .split("")
        .reduce((acc, char, i) => char === '.' ? i : acc, 0);
    return ((val.length === 0) || (at > -1) && (grDotIdx > at)) ? '' : 'въведете валиден email'
}
// function isMoreThan20(val) {
//     return (val.length === 0) || (val.length > 19 ) ? '' : 'минимум 20 символа'
// }

function HToUse() {
    const classes = useStyles();

    const module = "howToUse";

    const remoteServerInterface = useRemoteServer();
    const [how2Use, setHow2Use] = React.useState({});

    const defaultValues = {
        name: '',
        email: '',
        hospital: '',
        question: ''
    }
    const defaultErrors = {
        name: [],
        email: [],
        hospital: [],
        question: []
    }

    const [values, setValues] = React.useState(defaultValues);
    const [errors, setErrors] = React.useState(defaultErrors);

    const [openModal, setOpenModal] = React.useState(false);

    React.useEffect(async () =>{
        const list = await remoteServerInterface.getSingleton(module);
        setHow2Use(list)
    },[])

    const  handleSubmit = (ev)=>{
        ev.preventDefault();

        remoteServerInterface.postSaveHow2UseForm(values.name, values.email, values.hospital, values.question);
        //set empty form after submit
        setValues(defaultValues);
        setErrors(defaultErrors);
        setOpenModal(true);
    }

    // const handleOpenModal = () => {
    //     setOpenModal(true);
    // };
    const handleCloseModal = () => {
        setOpenModal(false);
        // window.location = `${process.env.PUBLIC_URL}/`;
    };

    return (
        <PageContainer>
        {/*----------------------------  FOR DELETE  ------------------------*/}
            <div className="text-center pb-4">
                <img height="79" src={COCKPIT_HOST + COCKPIT_UPLOADS_DIR + "/2021/05/10/609977c741526tai_home.png"}/>
            </div>
        {/*----------------------------  FOR DELETE  ------------------------*/}
            <h3 className="ps-2 pb-2 fst-italic text-secondary border-bottom border-2 border-secondary">{how2Use.Title ? how2Use.Title : null}</h3>
            <article className="row pb-0 pt-1 pb-xxl-5" dangerouslySetInnerHTML={{__html: how2Use.Description}}></article>
            <div className="pt-5 text-center">
                <h3 className="ps-2 pb-2 fst-italic text-secondary">{how2Use.Contac_form_title ? how2Use.Contac_form_title : null}</h3>
                <form onSubmit={handleSubmit} className="col-lg-8 col-xl-6 px-4 pt-3 d-inline-block" noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <InputField
                                background="filled"
                                label = "Име *"
                                name = 'name'
                                value={values.name}
                                onChange={val => {
                                    const name = val;
                                    setValues(prev => ({
                                        ...prev,
                                        name
                                    }));
                                }} 
                                validations={[isRequired, isMoreThan3]}
                                errors={errors.name}
                                setErrors = {setErrors}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputField
                                background="filled"
                                label = "Електронен адрес *"
                                name = 'email'
                                value={values.email}
                                onChange={val => {
                                    const email = val;
                                    setValues(prev => ({
                                        ...prev,
                                        email
                                    }));
                                }} 
                                validations={[isRequired, isEmail]}
                                errors={errors.email}
                                setErrors = {setErrors}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <InputField
                                background="filled"
                                label = "Здравно заведение *"
                                name = 'hospital'
                                value={values.hospital}
                                onChange={val => {
                                    const hospital = val;
                                    setValues(prev => ({
                                        ...prev,
                                        hospital
                                    }));
                                }} 
                                validations={[isRequired, isMoreThan3]}
                                errors={errors.hospital}
                                setErrors = {setErrors}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <InputField
                                background="filled"
                                type="textarea"
                    
                                numRows='5'
                                label = "Запитване *"
                                name = 'question'
                                value={values.question}
                                onChange={val => {
                                    const question = val;
                                    setValues(prev => ({
                                        ...prev,
                                        question
                                    }));
                                }} 
                                validations={[isRequired]}
                                errors={errors.question}
                                setErrors = {setErrors}
                            />
                        </Grid>
                    </Grid>
                    <div className="d-flex justify-content-between">
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Изпрати
                        </Button>
                        <p className="text-gray d-flex align-items-end pt-3">* задължителни полета</p>   
                    </div>
                </form>
                {/*<form onSubmit={handleSubmit} className={classes.form +" px-4 pt-3 border border-gray"} noValidate>
                    <TextField
                        variant="standard"
                        margin="normal"
                        required
                        className="w-50 pe-4"
                        value={user}
                        onInput={ e=>setUser(e.target.value)}
                        id="email"
                        label="Име"
                        name="user"
                        autoComplete="user"
                        autoFocus
                        error={error}
                        helperText={error}
                    />
                    <TextField
                        variant="standard"
                        margin="normal"
                        required
                        className="w-50"
                        value={email}
                        onInput={ e=>setEmail(e.target.value)}
                        id="email"
                        label="Електронен адрес"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        error={error}
                        helperText={error}
                    />
                    <TextField 
                        variant="standard"
                        margin="normal"
                        required
                        multiline
                        rows={4}
                        minHeight
                        value={userQuestion}
                        onInput={ e=>setUserQuestion(e.target.value)}
                        name="question"
                        label="Запитване..."
                        type="textarea"
                        id="question"
                        error={error}
                        fullWidth
                    />
                    <p className="text-gray pt-3">* задължителни полета</p>    
                    <Button
                        type="submit"
                        minHeight
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                    Изпрати
                    </Button>
                </form> */}
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="d-flex justify-content-center align-items-center"
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
                >
                <Fade in={openModal}>
                    <div className="bg-light p-5 text-center">
                        <h2 id="transition-modal-title" className="text-blue pb-4">Благодарим Ви, че се свързахте с нас!</h2>
                        <p id="transition-modal-description" className="text-secondary">Ще отговорим на вашето запитване възможно най-скоро.</p>
                    </div>
                </Fade>
            </Modal>
        </PageContainer>
    );
}

export default HToUse;