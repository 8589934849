import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Redirect } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import {COCKPIT_HOST} from "../../config";

import useRemoteServer from '../../hooks/useRemoteServer';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#AF0061',
    borderRadius: '0',
    marginTop: '24px',
    '&:hover' : {
        background: '#AF0061',
        opacity: '0.8'
    }
  },
}));


function Login({onLogIn}) {
    const location = useLocation()
    const referrer = location.state ? location.state.referrer.pathname : '/about-medics';

    const classes = useStyles();

    const remoteServer = useRemoteServer();
    const [loggedIn, setLoggedIn] = React.useState(false);

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [error, setError] = React.useState('');

    // const url = props.location;
    // const location = useLocation();

    React.useEffect(()=>{
        setLoggedIn(window.localStorage.getItem('taitest.loggedin'));
    },[loggedIn])

    const handleSubmit = ev => {
        ev.preventDefault();

        // Check in collection registeredMedics if user is ACTIVE and (PROMO)
        remoteServer.getIsActivPromo({"Medic_email": email}).then(
            (res)=>{
                if(res.error){
                    console.log(res.error);
                } else {
                    // 1. User is NOT registered
                    if(res.entries.length === 0){
                        // 1.1 Set user to inactive in ACCOUNTS
                        // remoteServer.setActiveFalse
                        setError("грешен адрес или парола!")

                    // 2. User is NOT active
                    } else if(res.entries[0].Active === false){
                        setError("грешен адрес или парола!")
                        
                    // 2. User is active
                    } else if(res.entries[0].Active === true){
                        // 1.1 Set user to active in ACCOUNTS
                        // remoteServer.setActiveTrue


                        // 1.2 Check is user authenticate
                        remoteServer.postLogin(email, password).then(
                            (result)=>{
                                if(result.error){
                                    
                                    if (result.error="Authentication failed") {
                                        setError("грешен адрес или парола!");
                                    }
                                } else {
                                    window.localStorage.setItem('taitest.id', result.medic_id ? result.medic_id : 0);
                                    window.localStorage.setItem('taitest.name', result.name);
                                    window.localStorage.setItem('taitest.loggedin', true);
                                    setLoggedIn(true);
                                    onLogIn();
                                    //redirect
                                }
                            }
                        )
                    } 
                }
            }
        )
    }

    return (
        <React.Fragment>
            {loggedIn && (<Redirect to={referrer} />)}        
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className="d-flex align-items-center flex-column mt-5 pb-5 pt-0 pt-sm-5">
                    
                    <AccountBoxIcon  className="font-size-80 text-secondary" />
                    <h3  className="pt-3">ВХОД</h3>
                    <form onSubmit={handleSubmit} className={classes.form} noValidate>
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            value={email}
                            onInput={ e=>(setEmail(e.target.value), setError(""))}
                            id="email"
                            label="Електронен адрес"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            error={error !== ""}
                            helperText={error}
                        />
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            fullWidth
                            value={password}
                            onInput={ e=>(setPassword(e.target.value), setError(""))}
                            name="password"
                            label="Парола"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            error={error !== ""}
                           
                        />
                        <p className="text-gray pt-3">* задължителни полета</p>    
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                        Вход
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href={COCKPIT_HOST+"auth/forgotpassword"} variant="body2" className="text-secondary">
                                    Забравена парола?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="preview/registration" variant="body2" className="text-secondary">
                                    {"Не сте се регистрирали? Регистрация"}
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
        </React.Fragment>
    );
}

export default Login;    