import React from "react";
import {Link, Redirect} from "react-router-dom";


function RedirectAnonymous({url}) {    
    const [redirect, setRedirect] = React.useState(false);
    const [state, setState] = React.useState(url);
   


    React.useEffect(()=>{
        const loggedIn = window.localStorage.getItem('taitest.loggedin');
        if(loggedIn !== 'true'){
            setRedirect(true);
        }
    })

    return (
        <React.Fragment>
            {redirect && (<Redirect to={{pathname: '/login', state: { referrer: url }}} />)}
        </React.Fragment>
    ) 
}

export default RedirectAnonymous;