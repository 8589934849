
import React from "react";
import { useParams, useLocation } from "react-router-dom";  
import {COCKPIT_HOST, COCKPIT_API_IMAGE, COCKPIT_GET_TOKEN} from "../../config";

import useRemoteServer from '../../hooks/useRemoteServer';

import RedirectAnonymous from './../Authentication/RedirectAnonimus';
import BlogListing from "./BlogListing";
import BlogDetailed from "./BlogDetailed";
import BlogNavMenu from "./BlogNavMenu";
import BlogBreadcbumbs from "./BlogBreadcrumbs";

function Blog({coverImage, pageTitle, alias, metaTitle, metaDescription, isPrivatePage}) {
    const loggedIn = localStorage.getItem('taitest.loggedin');
    const location = useLocation()

    const { article } = useParams();

    const remoteServerInterface = useRemoteServer();
    const [navData, setNavData] = React.useState({entries:[]});

    React.useEffect(async () =>{
        const navList = await remoteServerInterface.getNavigationBlog();
        setNavData(navList);
    },[])

    const [blogData, setBlogData] = React.useState({entries:[]});

    const [startOrder, setStartOrder] = React.useState(0);
    const [displayButton, setDisplayButton] = React.useState(false);


    React.useEffect(async () =>{
        if(article){
            const blogList = await remoteServerInterface.getBlogDataHome({"Alias_slug": article});
            setBlogData(blogList);

        }else{ 
            const blogList = await remoteServerInterface.getBlogData(alias, startOrder, loggedIn ? "": 'PATIENT');
            setBlogData(blogList);
            setStartOrder(blogList.entries[blogList.entries.length - 1]._o + 1);
            if (blogList.entries.length > 3) {
                setDisplayButton(true);
            }
        }
    },[])

    const handleClick = async (event) => {
        const blogList = await remoteServerInterface.getBlogData(alias, startOrder);
        const newBlogData = Object.assign({}, blogData, {entries: blogData.entries.concat(blogList.entries)});
        setBlogData(newBlogData);
        if(blogList.entries.length === 0){
            setDisplayButton(false);
        } else {
            setStartOrder(blogList.entries[blogList.entries.length - 1]._o + 1)
        }
    };


    
    return (
        <React.Fragment>
            { !loggedIn && isPrivatePage && (<RedirectAnonymous url={location}/>)}
            <div className="w-100">
                <article className="pb-4">
                    <div className="cover-img w-100" 
                         style={{backgroundImage: `url(${COCKPIT_API_IMAGE}?token=${COCKPIT_GET_TOKEN}&src=${COCKPIT_HOST+coverImage.path}&w=1920&o=1&m=thumbnail&q=80)`}}>
                    </div>
                    <div className="position-relative container p-0">
                        <div className="w-100 cover-page-text position-absolute">
                            <div className="offset-8 col-4 background-light">
                                <span className="pe-4 d-flex justify-content-end">
                                    <h1 className="px-5 header text-uppercase fw-bold text-gray px-4 pt-4 pb-3">{pageTitle ? pageTitle : ""}</h1>
                                </span>
                            </div>
                        </div>
                    </div>   
                </article>
            </div>
            <div className="container pb-0 pb-lg-5 pb-md-3">
                <article className="pb-2 pb-md-3 pb-lg-5">
                    <BlogBreadcbumbs navData={navData} blogData={blogData} alias={alias} article={article}/>
                    <div className="row">
                        <BlogNavMenu menuList={navData}/>
                        <div className="col-12 col-md-7 col-lg-8">
                            <hr className="pt-1 mb-2 bg-secondary border-secondary border-top border-bottom border-1"/>
                            <div className="border-secondary border-top border-2"></div>
                                {article ? (<BlogDetailed blogItemData={blogData} alaisArticle={article}/>) : (<BlogListing blogListData={blogData} button={displayButton} onLoadMore={handleClick} metaT={metaTitle} metaD={metaDescription} />)}
                        </div>
                    </div>
                </article>
            </div>
        </React.Fragment>
    );
}

export default Blog;
