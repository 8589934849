
import React from "react";
import {Link} from "react-router-dom";  
import {COCKPIT_HOST, COCKPIT_UPLOADS_DIR, COCKPIT_API_IMAGE, COCKPIT_GET_TOKEN} from "../../config";
import  Slider from "./Slider";

import useRemoteServer from '../../hooks/useRemoteServer';
// import RedirectAnonymous from './Authentication/RedirectAnonimus';
// import Metatag from '../components/MetaData';

function Home({itemId}) {
    const module = "homePage";

    const remoteServerInterface = useRemoteServer();
    const [homeData, setHomeData] = React.useState({Slider:[], Content_section:[], Image_news:{}});
    const [blogData, setBlogData] = React.useState({entries:[]});
    const loggedIn = localStorage.getItem('taitest.loggedin');


    React.useEffect(async () =>{
        const list = await remoteServerInterface.getSingleton(module);
        setHomeData(list)
    },[])
    React.useEffect(async () =>{
        const blogList = await remoteServerInterface.getBlogDataHome({"Home_page":true});
        setBlogData(blogList);
    },[])

    const themeList = homeData.Content_section.map(function(val, ind) {

        const linkTo =  val.value.link
        return (
            <Link to={linkTo} key={ind} className="col-12 col-md-6 col-lg-4 pb-4 pb-lg-0 px-3 d-flex align-items-center text-decoration-none">
                <div className="btn-svg-box d-flex flex-column align-items-center justify-content-center w-100 pb-4 pt-5">
                    <h1 className="fw-light">{val.value.title}</h1>
                    <img className="mt-3 mb-5" src={COCKPIT_HOST+COCKPIT_UPLOADS_DIR+val.value.image.path} />
                    <div className="action-text-wrapper w-100">
                        <h4 className="action-text fw-normal">{val.value.action_title}</h4>
                    </div>
                </div>
            </Link>
        )
    })

    const blogList = blogData.entries.map (function(val, ind) {

        return (
            <Link to={"blog/"+val.Theme.display+"/"+val.Alias_slug} key={ind} className="col-12 col-md-6 col-lg-4 pb-5 pb-lg-0 px-3 d-flex align-items-center text-decoration-none">
                <div className="text-secondary blog-article">
                <div className="solid-circle position-relative mb-4">
                    <img className="blog-img w-100" src={COCKPIT_API_IMAGE+"?token="+COCKPIT_GET_TOKEN+"&src="+COCKPIT_HOST+val.Image.path+"&w=461&h=295&o=1&m=thumbnail&q=80"} />
                    <span className="">
                        <img className="blog-hover-img position-absolute" src={COCKPIT_HOST+"storage/SVG/article_hover_icon.svg"} />
                    </span>
                    <div className="thin-red-line w-100"></div>
                </div>
                    <h4 className="fst-italic">{val.Title}</h4>
                    <p className="fs-5 fw-light text-primary">{val.Description_preview}</p>
                    <h5 className="fst-italic fw-bolder text-uppercase">Виж повече</h5>
                </div>
            </Link>
        )
    })

    return (
        <React.Fragment>
            <div className="w-100">
                <article className="pb-5">   
                    <Slider slides={homeData.Slider}/>
                </article>
            </div>
            <div className="container pb-0 pb-lg-5 pb-md-3">
                <article className="pb-2 pb-md-3 pb-lg-5">   
                    <h1 className="pb-1 mb-4 ps-0 fw-bolder display-1 text-light border-2 border-secondary">{homeData.Heading_content ? homeData.Heading_content : null}</h1>
                    <div className="row">
                        {themeList}
                    </div>
                </article>
                <article className="pb-0 pb-lg-5">   
                    <h1 className="pt-4 pb-1 mb-4 ps-0 fw-bolder display-1 text-light border-2 border-secondary">{homeData.Heading_news ? homeData.Heading_news : null}</h1>
                    <div className="row">
                        {blogList}
                    </div>
                </article>
            </div>
        </React.Fragment>
    );
}

export default Home;
