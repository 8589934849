import React from 'react';
import { useLocation } from 'react-router-dom'
import { COCKPIT_HOST, COCKPIT_UPLOADS_DIR } from "../../config";

import PageContainer from './../PageContainer';
import Tai12Form from './Tai12Form';
import Tai12Questions from './Tai12Questions';
import Tai12Score from './Tai12Score';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import useRemoteServer from '../../hooks/useRemoteServer';
import RedirectAnonymous from './../Authentication/RedirectAnonimus';

function Tai12 (){
    const location = useLocation();

    const [openModal, setOpenModal] = React.useState(false);
    const [modalText, setModalText] = React.useState("");

    const handleCloseModal = () => {
        setOpenModal(false);
        // window.location = `${process.env.PUBLIC_URL}/`;
    };

    const loggedID = localStorage.getItem('taitest.id');
    const loggedName = localStorage.getItem('taitest.name');
    const [savedAnswers, setSavedAnswers] = React.useState(new Map(JSON.parse(window.localStorage.getItem('taitest.tai12.answers'))));
    const [patientData, setPatientData] = React.useState({        
        Medic_name: loggedName,
        Date: new Date(),
        Sex: null,
        Age: '',
        Education: null,
        Smoker: null,
        Diagnosis: null,
        Disease_period: '',
        Inhaler: null
    });

    const handleSubmit = ev => {
        ev.preventDefault();
    }
     
    const remoteServerInterface = useRemoteServer();
    const [questionsData, setQuestionsData] = React.useState([]);

    React.useEffect(async () => {
        const promiseList10 = remoteServerInterface.getTai10Questinos();
        const promiseList12 = remoteServerInterface.getTai12Questinos();
        Promise.all([promiseList10, promiseList12]).then(data => {
            const list10 = data[0];
            const list12 = data[1];
            setQuestionsData(list10.entries.concat(list12.entries));
        });

    },[])
    

    const [score, setScore] = React.useState({});
    const [adherence, setAdherence] = React.useState();
    const [allScores, setAllScores] = React.useState();

    const handleTai12Ready = React.useCallback(
        (value, answers) => {
            const answersStringified = JSON.stringify(Array.from(answers.entries()));
            window.localStorage.setItem('taitest.tai12.answers', answersStringified);
            
            // const sumSporDelibUncon = Number(value.sporadic + value.deliberate + value.unconscious)
            const sumScores = Number((value.sporadic + value.deliberate));
            // console.log(sumSporDelibUncon);

            let adherenceInx = 0;
            if (sumScores <= 45) {
                adherenceInx = 0;
            } else if((sumScores > 45)&&(sumScores < 50)){
                adherenceInx = 1;
            } else if(sumScores == 50){
                adherenceInx = 2;
            }
        
            setSavedAnswers(answers);
            setScore(value);
            setAdherence(adherenceInx);
            setAllScores(sumScores + Number(value.unconscious));
            

        },[setSavedAnswers, setScore, setAdherence, setAllScores]);


    const saveTestData = async (loggedId, currentResult) => {
        const entries = (await remoteServerInterface.getMedicData(loggedId)).entries;

        const entry = entries[0];
        const countTests = Number(entry ? entry.Number_of_tests : 0) + 1;
        const medicName = loggedName;
        const medicID = loggedId;
        const totalResult = Number(entry ? entry.Total_score : 0);
        const result = Number(currentResult + totalResult);
        if (entry){
            remoteServerInterface.saveMedicData(medicName, medicID, countTests, result, entry._id);
        } else {
            remoteServerInterface.saveMedicData(medicName, medicID, countTests, result);
        }

        if (inPromo){
            const savedAnswersObject = [...savedAnswers].reduce((result, item) => { result[`Q${item[0]}`] = item[1]; return result;}, {})
            const allInOneData = Object.assign(patientData, {Medic_id: loggedId}, savedAnswersObject, {Score_1_5: score.sporadic}, {Score_6_10: score.deliberate}, {Score_11_12: score.unconscious});
            // const allData= { ...patientData, ...savedAnswers, Medic_id: loggedID };
            remoteServerInterface.savePatientData(allInOneData);
        } else {
            console.log("You are not in campaign");
        }
    }

    const [inPromo, setInPromo] = React.useState(false);

    remoteServerInterface.getIsActivPromo({"Medic_id": loggedID}).then(
        (result)=>{
            if(result.error){
                console.log(result.error);
            } else {
                if (result.entries.length > 0 && result.entries[0].Promo){
                        setInPromo(true);
                }
            }
        }
    )

    const hasEmptyProp = Object.keys(patientData).filter(prop => patientData[prop] === null || patientData[prop] === '')
    // console.log(hasEmptyProp.map(val => ()));
    // console.log(savedAnswers.size);

    const handleNewTest = React.useCallback(() => {
        if(hasEmptyProp.length !== 0) {
            const translatedProp = {
                Medic_name: 'Лекар',
                Date: 'Дата',
                Sex: 'Пол',
                Age: 'Възраст на пациента',
                Education: 'Образование',
                Smoker: 'Тютюнопушене',
                Diagnosis: 'Диагноза',
                Disease_period: 'Давност на заболяването',
                Inhaler: 'Вид инхалатор'
            }

            setModalText('Моля попълнете: ' + hasEmptyProp.map(el => ' "'+translatedProp[el]+'" '))
            setOpenModal(true);
        } else if (savedAnswers.size !== 12){
            setModalText('Моля попълнете всчики въпроси от 1 до 12!');
            setOpenModal(true);
        } else {
            window.localStorage.setItem('taitest.tai12.answers', JSON.stringify([]));
            setSavedAnswers(new Map());
            saveTestData(loggedID , allScores);
            setScore({});
            setPatientData({
                Medic_name: '',
                Date: null,
                Sex: null,
                Age: '',
                Education: null,
                Smoker: null,
                Diagnosis: null,
                Disease_period: '',
                Inhaler: null
            });
            setAdherence("");

            // setModalText('Информацията е записана');
            // setOpenModal(true);
        }
    }, [setSavedAnswers, saveTestData, setAdherence, setAdherence]);

    const handleQuestionSelected = (questionNumber, questionScore) => {
        savedAnswers.set(questionNumber, questionScore);
        setSavedAnswers(new Map(savedAnswers));
    }


    return (
        <PageContainer>
            <RedirectAnonymous url={location}/>
            <article className="row px-sm-0 px-1">
                {/*----------------------------  FOR DELETE  ------------------------*/}
                    <div className="text-center pb-5">
                        <img height="79" src={COCKPIT_HOST + COCKPIT_UPLOADS_DIR + "/2021/05/10/609977c741526tai_home.png"}/>
                    </div>
                {/*----------------------------  FOR DELETE  ------------------------*/}
                <form onSubmit={handleSubmit} className="tai-test">
                    <div className="do-not-print">
                        <h3 className="fw-bold fst-italic text-secondary pb-3">Тест за придържане към инхалатори (12 въпроса) TAI 12.</h3>
                        <p className="text-dark lh-lg">
                            За да попълните въпросника, отбележете отговора, който най-добре описва настоящото Ви състояние. Отговорете на всички въпроси възможно най-честно. Точките съответстващи на всеки отговор, ще се появят автоматично в края на реда. След попълване на всички отговори, резултатът ще се появи в края на теста.
                            Попълването на дата и друга информация не е задължително.
                            След попълването на теста, можете да го принтирате или запазите в PDF формат, чрез бутона „Print/Save to PDF”.
                        </p>
                        <p className="pb-4">
                            Благодарим ви за сътрудничеството.
                        </p>
                    </div>
                    <div className="row pt-3 px-lg-5 px-0 mx-lg-5 mx-0">
                        <h4 className="ps-2 pb-2 fw-normal fst-italic text-secondary border-bottom border-2 border-gray print-border">
                            Информация за пациента
                        </h4>
                    </div>
                    <Tai12Form data={patientData} onChange={data => setPatientData(data)} campaign={inPromo}/>
                    <div className="row pt-5 px-lg-5 px-0 mx-lg-5 mx-0">
                        <h4 className="ps-2 pb-2 fw-normal fst-italic text-secondary border-bottom border-2 border-gray print-border">
                            <span className="fw-bold text-secondary"><span className="do-not-print" >TAI 10.</span></span> Определяне на нивото на придържане
                        </h4>
                    </div>
                    <Tai12Questions questions={questionsData} onTestReady={handleTai12Ready} answers={savedAnswers} onQuestionSelected={handleQuestionSelected}/>
                    <Tai12Score score={score} adherenceType={adherence} />
                    <div className="col-12 pt-5 pb-3 pe-3 do-not-print">
                        <div className="px-lg-5 px-0 mx-lg-5 mx-0 d-flex justify-content-sm-end justify-content-center"> 
                            <div className="row">
                                <div className="d-flex justify-content-end pe-5">
                                    <button onClick={handleNewTest} type="button" className="btn btn-blue">Запази</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="d-flex justify-content-end pe-0">
                                    <button onClick={window.print} type="button" className="btn btn-blue">Print / Save to PDF</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </article>
            <Modal  
                onAppearText={modalText}
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="d-flex justify-content-center align-items-center"
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 50,
                }}
                >
                <Fade in={openModal}>
                    <div className="bg-light p-5 text-center">
                        <h3 id="transition-modal-title" className="text-blue pb-4">Непълна информация</h3>
                        <p id="transition-modal-description" className="text-secondary">{modalText}</p>
                    </div>
                </Fade>
            </Modal>
        </PageContainer>
    )
}

export default Tai12;