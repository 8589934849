
import React from "react";
import {Link} from "react-router-dom";  
import { COCKPIT_HOST, COCKPIT_UPLOADS_DIR } from "../config";

import PageContainer from './PageContainer';
import useRemoteServer from '../hooks/useRemoteServer';

function AboutTAI() {
    const module = "aboutTaiTest";

    const remoteServerInterface = useRemoteServer();
    const [taiData, setTaiData] = React.useState({Why_questions:[]});
    const loggedIn = localStorage.getItem('taitest.loggedin');

    React.useEffect(async () =>{
        const list = await remoteServerInterface.getSingleton(module);

        setTaiData(list)
    },[])


    const whyQuestions = taiData.Why_questions.map (function(val, ind) {
        return (
            <div key={ind} className="col-md-4 col-12 p-3">
                <h5 className="text-secondary pb-3">{val.value["Why TAI Title"]}</h5>
                <p className="text-secondary">{val.value["Why TAI Descr"]}</p>
            </div>

        )
    })

    const linkTai10 = taiData.Link_page ? taiData.Link_page[0].value.Link_to_Page.display : '';
    const linkTai12 = taiData.Link_page ? taiData.Link_page[1].value.Link_to_Page.display : '';

    return (
        <PageContainer> 
        {/*----------------------------  FOR DELETE  ------------------------*/}
            <div className="text-center pb-4">
                <img height="79" src={COCKPIT_HOST + COCKPIT_UPLOADS_DIR + "/2021/05/10/609977c741526tai_home.png"}/>
            </div>
        {/*----------------------------  FOR DELETE  ------------------------*/}
            <div className="row pt-3 pb-5 mb-0 mb-sm-4 justify-content-center">
                <div className="col-lg-4 col-md-4 col-sm-12 pb-md-0 pb-3 d-flex align-items-center">
                    <Link to={linkTai10} className="text-decoration-none">
                        <div className="box-link d-flex flex-column align-items-center justify-content-center">
                            <div className="box-link-title pb-3">
                                <h1 className="fw-normal">{taiData.Link_page ? taiData.Link_page[0].value.Link_Title : null}</h1>
                            </div>
                            <p className="box-link-descr small fst-italic text-center text-uppercase px-3 text-white">{taiData.Link_page ? taiData.Link_page[0].value.Link_Descr : null}</p>
                        </div>
                    </Link>
                </div>
                {loggedIn && (<div className="col-lg-4 col-md-4 col-sm-12 d-flex align-items-center">
                <Link to={linkTai12} className="text-decoration-none">
                        <div className="box-link d-flex flex-column align-items-center justify-content-center">
                            <div className="box-link-title pb-3">
                                <h1 className="fw-normal">{taiData.Link_page ? taiData.Link_page[1].value.Link_Title : null}</h1>
                            </div>
                            <p className="box-link-descr small fst-italic text-center text-uppercase px-3 text-white">{taiData.Link_page ? taiData.Link_page[1].value.Link_Descr : null}</p>
                        </div>
                    </Link>
                </div>)}
            </div>
            <h4 className="ps-2 pb-2 fst-italic text-secondary border-bottom border-2 border-secondary">{taiData.Title ? taiData.Title : null}</h4>
            <article className="row pt-2 pb-0" dangerouslySetInnerHTML={{__html: taiData.Description}}></article>

            <h4 className="ps-2 pt-5 pb-2 fst-italic text-secondary border-bottom border-2 border-secondary">{taiData.Title_2 ? taiData.Title_2 : null}</h4>
            <article className="row pt-2 pb-5" dangerouslySetInnerHTML={{__html: taiData.Description_2}}></article>
            <article className="why-tai-questions row p-3 mb-4">
                {whyQuestions}
            </article>

            {!loggedIn && (
                <div>
                    <h4 className="ps-2 pt-5 pb-2 fst-italic text-secondary border-bottom border-2 border-secondary">{taiData.Title_3 ? taiData.Title_3 : null}</h4>
                    <article className="row pt-4 pb-5" dangerouslySetInnerHTML={{__html: taiData.Description_3}}></article>
                </div>
            )}
            {loggedIn && (
                <div>
                    <h4 className="ps-2 pt-5 pb-2 fst-italic text-secondary border-bottom border-2 border-secondary">{taiData.Title_4 ? taiData.Title_4 : null}</h4>
                    <article className="row pt-4 pb-5" dangerouslySetInnerHTML={{__html: taiData.Description_3}}></article>
                    <article className="row pt-4 pb-5" dangerouslySetInnerHTML={{__html: taiData.Description_4}}></article>
                </div>
            )}
        </PageContainer>
    );
}

export default AboutTAI;