import React from 'react';
// import { Link } from 'react-router-dom';

const NotFound = () => (
    <div className="row pt-5 w-100 justify-content-center align-items-center">
        <div className="col-4">
            <h1 className="fw-normal display-1 text-secondary">404</h1>
            <h1 className="text-secondary">UH OH! You're lost.</h1>
            <p>The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage.</p>
            <div className="row ps-2 pt-4 d-flex">
                <a href="/" className="w-25 btn btn-secondary">
                Home
                </a>
            </div>
        </div>
    </div>
);

export default NotFound;